.switchWrapper {
    border-radius: 20px;
    cursor: pointer;
    height: 20px;
    width: 40px;
    float: left;
    overflow: hidden;
    position: relative;
}

.switchWrapper>input[type=checkbox] {
    opacity: 0;
    position: absolute;
}

.switchWrapper>input[type=checkbox]:checked+.switch {
    transform: translateX(50%) translateX(-19px);
}

.switchWrapper>input[type=checkbox]+.switch {
    transform: translateX(5px);
}

.switch {
    color: #fff;
    background-color: rgb(255 214 0 / var(--tw-bg-opacity));
    display: inline-block;
    height: 100%;
    left: -100%;
    position: relative;
    transition: .1s linear;
    width: 200%;
}

.switch_disable {
    color: #fff;
    background-color: rgb(228, 225, 211);
    display: inline-block;
    height: 100%;
    left: -100%;
    position: relative;
    transition: .1s linear;
    width: 200%;
}

.switchHandle {
    background: #000;
    border-radius: 50%;
    display: inline-block;
    height: 14px;
    left: 13%;
    position: relative;
    top: 0px;
    width: 14px;
    z-index: 3;
}