.switchWrapper {
    border-radius: 20px;
    cursor: pointer;
    height: 33px;
    width: 70px;
    float: left;
    overflow: hidden;
    position: relative;
}

.switchWrapper>input[type=checkbox] {
    opacity: 0;
    position: absolute;
}

.switchWrapper>input[type=checkbox]:checked+.switch {
    transform: translateX(50%) translateX(-28px);
}

.switchWrapper>input[type=checkbox]+.switch {
    transform: translateX(5px);
}

.switch {
    /* color: #fff;
    background-color: #f1c40f; */
    background: rgb(255 255 255 / 0.2);
    backdrop-filter: blur(14.5px);
    display: inline-block;
    height: 100%;
    left: -100%;
    position: relative;
    transition: .1s linear;
    width: 200%;
}

.switchHandle {
    border-radius: 50%;
    display: inline-block;
    height: 26px;
    left: 8%;
    position: relative;
    top: 4px;
    width: 26px;
    z-index: 3;
}